<div>
  <tlv-weto-header [currentStep]="currentStep" [firstStepValue]="firstStepValue" (onBackEvent)="back()"></tlv-weto-header>

  <div class="columns">
    @if (currentStep === FAI_WIDGET_ROUTES.PTO && stepStatus !== 'LOADING') {
      <div class="column is-4">
        <img alt="Boitier de raccordement Fibre" src="/assets/images/boitier-pto.svg">
      </div>
    }
    <div class="column" [ngClass]="currentStep === FAI_WIDGET_ROUTES.PTO && stepStatus !== 'LOADING' ? 'is-8' : 'is-12'">
      <weto-elig-nd-pto-va manual-render="true" #widget></weto-elig-nd-pto-va>
    </div>
  </div>

  <div class="field buttons is-flex is-centered">
    <button type="button" class="button is-primary" (click)="next()" data-cy="continue"
      [disabled]="nextDisabled">
      {{stepStatus !== 'LOADING' ? 'Continuer' : 'Traitement en cours...'}}
    </button>
  </div>
</div>
