import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    Activation,
    Equipment,
    ProductFactory,
    PromotionModel,
    QuoteContextFaiModel,
    TYPE_MARKETING,
} from '@bytel/bytel-sales';
import { OrderModel } from '@models/order/order.model';
import { ProductRepository } from '@repositories/product.repository';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { PromotionsService } from '@services/promotions.service';
import { SalesForceService } from '@services/salesforce.service';
import { ValidatorsService } from '@services/validators.service';
import { Observable, Subject, of } from 'rxjs';
import { delayWhen, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import bind from '../../../../helper/decorators/bind';
import { EQUIPMENT_FILTER_LABELS } from 'src/app/constants/fai';
import { IFaiPackBundle } from '../offers.component';
import { PromotionDetails } from '@models/promotion-details.model';
import { TYPE_ELIGIBILITE } from '@interfaces/products.interface';

@Component({
    selector: 'tlv-offer-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-details.component.scss'],
    standalone: false
})
export class OfferDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input()
    public offer: IFaiPackBundle;
    @Output()
    public onOfferBundleUpdate: EventEmitter<IFaiPackBundle> = new EventEmitter<IFaiPackBundle>();
    @Input()
    public isDisabled: boolean;
    @Input()
    public canBeQuoteMixed: boolean;
    public ocBox4G: string[];
    public loader = false;
    public ocBox5G: string[];
    public applicablePromotions: PromotionDetails[] = [];
    public formPromo: FormGroup = new FormGroup({
        enablePromotions: new FormControl(false),
        promotion: new FormControl<{value: string; disabled: boolean} | null>({value: '', disabled: true})
    });
    public EQUIPMENT_FILTER_LABELS = EQUIPMENT_FILTER_LABELS;
    public rnaForm: FormGroup;
    public isRnaValid = true;
    public controlRnaInProgress = false;
    public isFaimUnlimited = false;
    public scrollOnOffer = false;
    public splitedEquipments: string[] = [];
    public productAssociated: Equipment;
    public isLoadingManualPromo = false;
    public _orderRecovery: OrderModel;
    public TYPE_ELIGIBILITE = TYPE_ELIGIBILITE;

    private _manualPromos: PromotionModel[] = [];
    private _destroy$ = new Subject<void>();

    constructor(private readonly faiEligibility: FaiEligibilityService,
                private readonly cartService: CartService,
                private readonly cartTeleSalesService: CartTeleSalesService,
                private readonly router: Router,
                private readonly promotionsService: PromotionsService,
                private readonly validatorsService: ValidatorsService,
                private readonly customerService: CustomerService,
                private readonly catalogService: CatalogService,
                private readonly salesForceService: SalesForceService,
                @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.ocBox4G = this.faiEligibility.currentCart.eligibility.box_4g?.offres ?? [];
        this.ocBox5G = this.faiEligibility.currentCart.eligibility.box_5g?.offres ?? [];
        this._orderRecovery = this.salesForceService.prefilledInfo.order;
    }

    public ngOnInit(): void {
        this.splitedEquipments = this.EQUIPMENT_FILTER_LABELS[this.offer.fai.equipment]?.split(/\set\s/) || [];
        this.isFaimUnlimited = ProductFactory.Is(this.offer.fai, 'FaimUnlimited');
        this.applicablePromotions = Object.values(this.offer.manualPromotionsDetails)
            .flat(1)
            .filter(p => p.caracteristicType !== TYPE_MARKETING.FOYER);
        if (this.isFaimUnlimited && this.offer.fai.data?.produit_prioritaire) {
            this.catalogService.getProductByGencode<Equipment>(this.offer.fai.data?.produit_prioritaire).subscribe((p: Equipment) => {
                this.productAssociated = p;
                this.splitedEquipments = [p.label];
            });
        }
        // keep manual promotion selected
        const selectedManualPromos =
            this.offer.cart.promotions.manual.filter(p => this.applicablePromotions.findIndex((promo) => promo.id === p.id) !== -1);
        if (selectedManualPromos.length) {
            this.formPromo.patchValue({
                enablePromotions: true,
                promotion: selectedManualPromos[0].id
            }, { emitEvent: false });
        }
        this.formPromo.get('enablePromotions').valueChanges.pipe(takeUntil(this._destroy$)).subscribe(this._onEnablePromotionsChange);
        this.formPromo.get('promotion').valueChanges.pipe(takeUntil(this._destroy$)).subscribe(this._onPromotionChange);

        if (this.offer?.fai?.isAssos) {
            this.rnaForm = new FormGroup({
                rna: new FormControl('', this._rnaValidators)
            });
            this.isRnaValid = false;
            this.rnaForm.get('rna').valueChanges.pipe(takeUntil(this._destroy$)).subscribe(this._onRnaValueChange);
        }
    }

    public ngAfterViewChecked(): void {

        if (this.scrollOnOffer) { return; }

        const scrollElt = this.document.defaultView.document.querySelector(`#${'offer-detail-' + this.offer.fai.data.gencode}`);
        if (scrollElt) {
            scrollElt.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
            this.scrollOnOffer = true;
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public removePromotion(): Observable<void> {
        if (this.offer.cart.promotions.manual.length) {
            return this.cartService.removeManualPromotion(this.offer.cart, this.offer.cart.promotions.manual[0]).pipe(
                tap(()=>this.onOfferBundleUpdate.emit(this.offer))
            );
        }
        return of(null);
    }

    public addPromotion(promo: PromotionModel): void {
        this.loader = true;
        of(this.offer.cart)
            .pipe(
                takeUntil(this._destroy$),
                delayWhen((cart) => this.cartService.addManualPromotion(cart, promo))
            )
            .subscribe((cart) => {
                this.offer = {
                    ...this.offer,
                    cart
                };
                this.loader = false;
                this.onOfferBundleUpdate.emit(this.offer);
            });
    }

    public addToCart(): void {
        this.cartTeleSalesService.clearQuoteFai();
        if (!this.canBeQuoteMixed) {
            this.cartTeleSalesService.clearQuoteAcquisition();
        }

        const indexQuoteFai = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        if (indexQuoteFai === null) {
            this.cartTeleSalesService.addQuote(new QuoteContextFaiModel());
        }


        let add = this.catalogService.getProductByGencode(this.offer.fai.gencode).pipe(// This is done to get upsells not returned from sapic offer
            mergeMap((p)=>this.cartTeleSalesService
                .addProduct(ProductRepository.Clone(p), this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel))),
            takeUntil(this._destroy$),
        );

        if (this.offer.cart.promotions.manual.length) {
            add = add.pipe(
                takeUntil(this._destroy$),
                delayWhen(() =>
                    this.cartTeleSalesService.addManualPromotion(this.offer.cart.promotions.manual[0])
                )
            );
        }

        add.subscribe(() => {
            this._addFmsRecovery();
            this.router.navigate(['panier']);
        });
    }

    public checkRnaValidity(): void {
        const rnaValue = this.rnaForm.get('rna')?.value as string;
        this.controlRnaInProgress = true;

        this.validatorsService.rnaNumberValidation(rnaValue).pipe(
            takeUntil(this._destroy$),
            finalize(() => {
                this.controlRnaInProgress = false;
            })).subscribe({next: () => {
            /* if (!response) {
                this.rnaForm.get('rna').setErrors({ invalid: true });
                return;
            }*/
            this.customerService.setRna(rnaValue);
            this.isRnaValid = true;
        }, error: () => {
            this.isRnaValid = true;
            // this.rnaForm.get('rna').setErrors({ invalid: true });
        }});
    }

    private _addFmsRecovery(): void {
        if (this._orderRecovery) {
            const fms: Activation = this.cartTeleSalesService.cartModel
                .getQuote(this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel))?.getProductByType('Activation');

            if (fms) {
                fms.priceType = this._orderRecovery.cart.payment.initialFmsPaymentType;
            }
        }
    }

    private _selectPromotionFromOrderRecovery(): void {
        const orderRecoveryPromoId: string = this._orderRecovery?.fai?.offer?.manualPromotionId;
        const appliedPromotion: PromotionModel = this._manualPromos.find((p: PromotionModel) => p.addProduct === orderRecoveryPromoId);

        if (orderRecoveryPromoId && appliedPromotion) {
            this._onEnablePromotionsChange(true);
            this.formPromo.patchValue({
                enablePromotions: true,
                promotion: appliedPromotion.id
            });
        }
    }

    @bind
    private _onEnablePromotionsChange(value: boolean): void {
        const checkBox = this.formPromo.get('promotion');
        const setValue = (): void => {
            if (value) {
                checkBox.enable();
            } else {
                checkBox.disable();
            }
        };
        checkBox.setValue(null);
        if (!this.offer.cart.promotions.manual.length) {
            this.isLoadingManualPromo = true;
            this.promotionsService.getPromotions(this.offer.cart).pipe(takeUntil(this._destroy$)).subscribe({
                next: (promotions) => {
                    this._manualPromos = promotions.manual;
                    this._selectPromotionFromOrderRecovery();
                    setValue();
                },
                complete: () => {
                    this.isLoadingManualPromo = false;
                }
            });
        } else {
            setValue();
        }
    }

    @bind
    private _onPromotionChange(value: number): void {
        if (value){
            this.removePromotion().pipe(takeUntil(this._destroy$)).subscribe(
                ()=>this.addPromotion(this._manualPromos.find((promo)=>promo.id === value))
            );
        } else {
            this.removePromotion().pipe(takeUntil(this._destroy$)).subscribe();
        }
    }

    @bind
    private _onRnaValueChange(value: string): void {
        this.isRnaValid = false;
        this.rnaForm.get('rna').patchValue(value.toUpperCase(), { emitEvent: false});
    }

    @bind
    private _rnaValidators(control: AbstractControl): ValidationErrors | null {

        let rnaMask: RegExp;
        if (!control.value) {
            return Validators.required(control);
        }

        if (control.value.startsWith('W')) {
            rnaMask = new RegExp(/^[a-zA-Z\d]{10}$/);
        } else {
            rnaMask = new RegExp(/^[a-zA-Z\d-]{7,14}$/);
        }

        return Object.assign({},
            Validators.required(control), Validators.pattern(rnaMask)(control));
    }
}
