<div>
  @if (firstStepValue && stepStatus === 'LOADING') {
    <div class="has-backdrop">
      <div class="loader map-loader-body">
        <div class="loader-body"></div>
      </div>
    </div>
  }

  <tlv-weto-header [currentStep]="currentStep" [firstStepValue]="firstStepValue" (onBackEvent)="back()"></tlv-weto-header>

  <div class="columns">
    @if (currentStep === FAI_WIDGET_ROUTES.PTO && stepStatus !== 'LOADING') {
      <div class="column is-4">
        <img alt="Boitier de raccordement Fibre" src="/assets/images/boitier-pto.svg">
      </div>
    }
    <div class="column" [ngClass]="currentStep === FAI_WIDGET_ROUTES.PTO && stepStatus !== 'LOADING' ? 'is-8' : 'is-12'">
      <weto-map-va manual-render="true" #widget></weto-map-va>
    </div>
  </div>

  @if (stepStatus !== 'LOADING' && currentStep === FAI_WIDGET_ROUTES.HOUSING && housings.length === 0) {
    <div class="columns is-centered" data-cy="cdl-warning">
      <div class="column is-narrow">
        <div class="notification is-small is-warning has-body">
          <div class="icon">
            <i class="tri-exclamation-circle"></i>
          </div>
          <div class="body">
            <h5 class="title is-5">L'adresse du client est introuvable.</h5>
            <p>
              <span class="is-block">Si vous continuez, vous allez rentrer dans un parcours de souscription avec construction de ligne.</span>
              <span class="is-block has-text-weight-semibold">Des frais de construction de ligne pourront être facturés</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  }

  @if (!firstStepValue) {
    <div class="field buttons is-flex is-centered">
      <button type="button" class="button is-primary" (click)="next()" data-cy="continue"
        [disabled]="nextDisabled">
        {{stepStatus !== 'LOADING' ? 'Continuer' : 'Traitement en cours...'}}
      </button>
    </div>
  }
</div>

