import { Pipe, PipeTransform } from '@angular/core';
import { ISapicFaiProductPromotion } from '@interfaces/products.interface';

@Pipe({
    name: 'getPromotionTotalAmount',
    standalone: false
})
export class GetPromotionTotalAmount implements PipeTransform {
    public transform(promotions: ISapicFaiProductPromotion[]): string {
        let amount = 0;
        if (promotions.length) {
            amount = promotions.reduce((acc, promo) => acc + promo.valeur, 0);
        }
        return amount ? amount.toFixed(2) + '€' : '--';
    }
}
