import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModules } from 'src/app/shared/shared.module';
import { FaiWidgetRoutingModule } from './fai-routing.module';

import { EligibilityComponent } from './eligibility/eligibility.component';
import { WetoFunnelComponent } from './eligibility/widget/weto-funnel/weto-funnel.component';
import { WetoNdComponent } from './eligibility/widget/weto-nd/weto-nd.component';

import { WetoEligibilityMapComponent } from './eligibility/widget/weto-eligibility-map/weto-eligibility-map.component';
import {
    WetoFaiOrderRecoverySummaryComponent
} from './eligibility/widget/weto-fai-order-recovery-summary/weto-fai-order-recovery-summary.component';
import { WetoHeaderComponent } from './eligibility/widget/weto-header/weto-header.component';
import { OfferDetailsComponent } from './offers/offer-details/offer-details.component';
import { OffersComponent } from './offers/offers.component';
import { StepsSummaryDataComponent } from './steps-summary-data/steps-summary-data.component';
import { TechnosComponent } from './technos/technos.component';
import { WetoMapComponent } from './eligibility/widget/weto-map/weto-map.component';
import { GetPromotionAmount } from './offers/pipes/promotion-amount.pipe';
import { GetPromotionTotalAmount } from './offers/pipes/promotion-total-amount.pipe';
import { WetoImbComponent } from './eligibility/widget/weto-imb/weto-imb.component';

@NgModule({
    declarations: [
        WetoHeaderComponent,
        WetoNdComponent,
        WetoFunnelComponent,
        WetoMapComponent,
        EligibilityComponent,
        TechnosComponent,
        OffersComponent,
        OfferDetailsComponent,
        StepsSummaryDataComponent,
        WetoHeaderComponent,
        WetoEligibilityMapComponent,
        WetoFaiOrderRecoverySummaryComponent,
        GetPromotionAmount,
        GetPromotionTotalAmount,
        WetoImbComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [WetoFunnelComponent],
    imports: [
        CommonModule,
        SharedModules,
        FaiWidgetRoutingModule
    ]
})
export class FaiWidgetModule { }
