import { Component, Input, OnInit } from '@angular/core';
import { OrderModel } from '@models/order/order.model';
import { SalesForceService } from '@services/salesforce.service';
import { FAI_WIDGET_ROUTES, FAI_CART_TYPE } from 'src/app/constants/fai';

@Component({
    selector: 'tlv-weto-fai-order-recovery-summary',
    templateUrl: './weto-fai-order-recovery-summary.component.html',
    styleUrls: ['./weto-fai-order-recovery-summary.component.scss'],
    standalone: false
})
export class WetoFaiOrderRecoverySummaryComponent implements OnInit {

    @Input() currentStep: string;

    public FAI_WIDGET_ROUTES = FAI_WIDGET_ROUTES;
    public FAI_CART_TYPE = FAI_CART_TYPE;

    public salesforceInlineAddress: string;
    public orderRecovery: OrderModel;

    public API_ERRORS = {
        NO_HOUSING_FOUND: '',
        SALESFORCE_ADDRESS: ''
    };

    constructor(protected salesForceService: SalesForceService) { }

    public ngOnInit(): void {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.salesforceInlineAddress = this.salesForceService.prefilledInfo?.address?.getInline();
        this.API_ERRORS.SALESFORCE_ADDRESS = this.salesForceService.prefilledInfo.eligibilityError?.message;
    }
}
