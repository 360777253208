<div class="columns">
  <div class="column is-12">
    @if (!firstStepValue) {
      <h2 class="icon-and-text has-cursor" (click)="back()">
        <span class="icon is-small is-circled is-small has-background-tertiary has-text-tertiary-invert"><i
        class="tri-arrow-left"></i></span>
        <span>{{CTA_BACK_LABELS[currentStep] ?? 'Retour'}}</span>
      </h2>
    }
    @if (!firstStepValue) {
      <div class="is-divider"></div>
    }
  </div>
</div>

@if (orderRecovery) {
  <tlv-weto-fai-order-recovery-summary [currentStep]="currentStep"></tlv-weto-fai-order-recovery-summary>
}

@if (!firstStepValue && !orderRecovery) {
  <div class="columns is-vcentered">
    <div class="column is-6 is-offset-3">
      <tlv-steps-summary-data></tlv-steps-summary-data>
    </div>
    <div class="column is-3 is-narrow has-text-right icon-map">
      <tlv-weto-eligibility-map></tlv-weto-eligibility-map>
    </div>
  </div>
}