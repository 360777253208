<tlv-hero-title title="Test d’éligibilité aux offres Bbox" iconName="tri-box"></tlv-hero-title>
<div class="is-divider is-invisible"></div>
<div class="is-divider is-invisible"></div>
<div class="columns is-centered" data-cy="fai-techno-step">
  <div class="column is-10">
    <div class="box">
      <div class="box-content">
        <h2 class="icon-and-text has-cursor" (click)="goToPreviousNext()">
          <span class="icon is-small is-circled is-small has-background-tertiary has-text-tertiary-invert"><i class="tri-arrow-left"></i></span>
          <span>Technologies disponibles pour ce client</span>
        </h2>
      </div>

      <div class="columns is-vcentered">
        <div class="column is-6 is-offset-3">
          <tlv-steps-summary-data></tlv-steps-summary-data>
        </div>
        <div class="column is-3 is-narrow has-text-right icon-map">
          <tlv-weto-eligibility-map></tlv-weto-eligibility-map>
        </div>
      </div>

      <div class="is-divider is-invisible"></div>

      @if (warningMessageCDLOrderReco) {
        <div class="is-flex is-justified-center">
          <div class="notification is-small is-info has-body" data-cy="cdl-warning-order">
            <div class="icon">
              <i class="tri-exclamation-circle"></i>
            </div>
            <div class="body">
              <h5 class="title">
                @if(orderRecovery?.fai?.isCDL && !isCDL) {
                  Le client n'est plus en construction de ligne : les frais de constructions de ligne
                  indiqués sur le récapitulatif de sa précédente commande ne lui seront pas facturés.
                }
              </h5>
            </div>
          </div>
        </div>
      }

      <div class="content">
        <!-- HANDLE ERRORS -->
        @if ((isCDL && !orderRecovery) || hasNoOneEligible) {
          <div class="notification is-small is-warning has-body" data-cy="cdl-warning">
            <div class="icon">
              <i class="tri-exclamation-circle"></i>
            </div>
            <div class="body">
              @if (hasNoOneEligible) {
                <p>
                  <span class="is-block">Aucune technologie éligible pour cette adresse.</span>
                </p>
              }
            </div>
          </div>
        }

        <form #formTechno="ngForm" (submit)="submit()">
          <table class="table is-bordered is-fullwidth is-marginless main-table">

            <colgroup>
            <col class="col-group-width">
            @for (tech of technos; track tech) {
              <col class="col-group-width">
            }
          </colgroup>

          <thead>
            <tr>
              <th class="is-invisible"></th>
              @for (tech of technos; track tech) {
                <th class="is-uppercase">{{tech.type}}</th>
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Eligible</td>
              @for (tech of technos; track tech; let i = $index) {
                <td [data-cy]="'techno-status-'+tech.type">
                  @if (tech.status === faiStatus.CONSTRUCTION) {
                    <div class="under-construction">
                      <span class="icon is-small"><i class="tri-exclamation-circle-s"></i></span>
                      <span class="info">Construction de ligne</span>
                    </div>
                  }
                  @if (tech.status !== faiStatus.CONSTRUCTION) {
                    <i class="icon-item status-icon">
                      @if (tech.status !== faiStatus.OK) {
                        <span class="icon is-medium has-text-danger" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                      }
                      @if (tech.status === faiStatus.OK) {
                        <span class="icon is-medium has-text-success" [data-cy]="'OK'"><i class="tri-check"></i></span>
                      }
                    </i>
                  }
                </td>
              }
            </tr>

            <tr>
              <td>Vitesse</td>
              @for (tech of technos; track tech; let i = $index) {
                <td [data-cy]="'techno-speed-'+tech.type">
                  @if (tech.status === faiStatus.CONSTRUCTION) {
                    <span class="na-info">__</span>
                  }
                  @if (tech.status === faiStatus.OK) {
                    <div class="is-flex is-justified-center is-vcentered">
                      <span class="icon-and-text">
                        <span class="icon is-small has-text-info is-marginless"><i class="tri-upload"></i></span>
                        <span class="is-size-6 has-text-weight-semibold" [data-cy]="'up'">{{tech.debitUpMax >= 1000 ? (tech.debitUpMax / 1000) : tech.debitUpMax}} {{tech.debitUpMax >= 1000 ? 'Gb' : 'Mb'}}/s</span>
                      </span>
                      <span class="icon-and-text">
                        <span class="icon is-small has-text-warning is-marginless"><i class="tri-download"></i></span>
                        <span class="is-size-6 has-text-weight-semibold" [data-cy]="'down'">{{tech.debitDownMax >= 1000 ? (tech.debitDownMax / 1000) : tech.debitDownMax}} {{tech.debitDownMax >= 1000 ? 'Gb' : 'Mb'}}/s</span>
                      </span>
                    </div>
                  }
                  @if (isTechnologiesPon(tech)) {
                    <p class="has-text-primary has-text-weight-bold">Eligible à l'Option Débit +</p>
                  }
                  @if (tech.status !== faiStatus.CONSTRUCTION && tech.status !== faiStatus.OK) {
                    <span class="icon is-medium has-text-danger" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                  }
                </td>
              }
            </tr>

            <tr>
              <td>Internet</td>
              @for (tech of technos; track tech) {
                <td [data-cy]="'techno-internet-'+tech.type">
                  @if (tech.status !== faiStatus.CONSTRUCTION && tech.status !== faiStatus.OK) {
                    <span class="icon is-medium has-text-danger" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                  }
                  @if (tech.status === faiStatus.CONSTRUCTION || tech.status === faiStatus.OK) {
                    <span class="icon is-medium has-text-success" [data-cy]="'OK'"><i class="tri-check"></i></span>
                  }
                </td>
              }
            </tr>

            <tr>
              <td>TV</td>
              @for (tech of technos; track tech) {
                <td [data-cy]="'techno-tv-'+tech.type">
                  @if ((tech.status !== faiStatus.CONSTRUCTION && tech.status !== faiStatus.OK) || !canHaveTV(tech)) {
                    <span class="icon is-medium has-text-danger" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                  }
                  @if ((tech.status === faiStatus.CONSTRUCTION || tech.status === faiStatus.OK) && canHaveTV(tech)) {
                    <span class="icon is-medium has-text-success" [data-cy]="'OK'"><i class="tri-check"></i></span>
                  }
                </td>
              }
            </tr>
          </tbody>
        </table>
        <!-- Forcecast && Validation-->
        <table class="table is-fullwidth main-table">
          <colgroup>
          <col class="col-group-width">
          @for (tech of technos; track tech) {
            <col class="col-group-width">
          }
        </colgroup>
        <!-- Without the thead the table lose his mind -->
        <thead class="is-hidden">
          <tr>
            <th></th>
            @for (tech of technos; track tech) {
              <th></th>
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="is-invisible no-border"></td>
            @for (tech of technos; track tech) {
              <td class="no-border">
                @if (tech?.allowForecast?.eligible) {
                  @if (tech?.allowForecast?.date && convertToReadableDate(tech?.allowForecast?.date)) {
                    <span [class.is-invisible]="!tech?.allowForecast?.date" class="is-size-8 is-block">{{convertToReadableDate(tech?.allowForecast?.date)}}</span>
                  }
                  @if (!tech?.allowForecast?.date || !convertToReadableDate(tech?.allowForecast?.date)) {
                    <span class="is-size-8 is-invisible is-block">Non Eligible</span>
                  }
                  <button type="button" class="button is-info is-small" [disabled]="isLoading" (click)="gotoForecastForm(tech)"
                  [class.is-loading]="isLoading" [data-cy]="'techno-contact-'+tech.type">Être contacté</button>
                } @else {
                  <div class="field no-select">
                    <div class="control is-flex is-justified-center">
                      <input [data-cy]="'techno-checkbox-'+tech.type" class="is-checkradio" id="{{tech.type}}" [(ngModel)]="selectedTechno[tech.type]" [disabled]="(tech.status === faiStatus.NOK) || lockOnSingleTechnoAvailable" name="{{tech.type}}" type="checkbox">
                      <label class="is-inline-flex" for="{{tech.type}}"></label>
                    </div>
                  </div>
                }
              </td>
            }
          </tr>
        </tbody>
      </table>
      <div class="buttons is-centered">
        <button data-cy="next" class="button is-primary" [disabled]="isLoading || hasNoOneEligible">{{isLoading ? 'Traitement en cours...' : 'Continuer'}}</button>
      </div>
    </form>

  </div>

</div>
</div>
</div>
