@if (stepsInfos.length) {
  <div class="is-flex is-justified-center has-text-left" data-cy="fai-steps-summary">
    <div class="notification is-small is-info has-body">
      <div class="icon">
        <i class="tri-exclamation-circle"></i>
      </div>
      <div class="body">
        <ul>
          @for (step of stepsInfos; track step) {
            <li>
              <span class="has-text-weight-semibold">{{step.split('#')[0]}}</span>
              @if (step.split('#')[1]) {
                : {{step.split('#')[1]}}
              }
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}